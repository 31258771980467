import configJson from "./config.json";
//todo: from env varables
export const {
  FORTIFIED_DATA_API_URL,
  OKTA,
  GOOGLE_ANALYTICS_TRACKING_ID,
  MAINTENANCE_MODE,
  // RECAPTHA_SITE_KEY,
} = configJson;

export const API_VERSION = "v1";
export const API_VERSION_V2 = "v2";
