/* eslint-disable */
import dateMath from "@elastic/datemath";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import moment from "moment-timezone";
import { RefObject } from "react";
import { FilterField } from "types/app";
import { capitalizeFirstLetter } from "excelUtils";
import {
  DAYS,
  DEFAULT_GROUP_BY_KEY,
  EnumTextFilterActions,
  ExportHiddenClass,
  LOCAL_TZ,
  NEGATE_FILTER,
  SilencingStatus,
  TABLE_ROW_ID,
  TIME_FRAMES,
  US_TIMEZONES_ABBR_MAPPER,
  US_TIME_ZONES_TO_GENERIC,
} from "./Consts";
import { store } from "./redux/store";

const MinutesPerDay = 60 * 24;
const MinutesPerHour = 60;

export const SEVEN_DAYS = 7;
export const DEFAULT_DAYS = 30;
export const FULL_DATE_FORMAT = "YYYY-MM-DD[T]HH:mm:ss"; // format of dates accepted from server
export const DAY_FORMAT = "YYYY-MM-DD";
export const DATE_PICKER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const TABLE_FORMAT = "MMM D, hh:mm:ss z A";
export const TABLE_FORMAT_FULL = "YYYY MMM D, HH:mm:ss z";
export const TABLE_FORMAT_MINUTES = "MMM D, HH:mm z";
export const TABLE_FORMAT_MINUTES_SERVER_TIME = "MMM D, HH:mm";
export const TABLE_FORMAT_NO_TZ = "YYYY MMM D, HH:mm:ss";
export const CHART_HEADER_FORMAT_NO_TZ = "YYYY MMM D, HH:mm";
export const CHART_HEADER_FORMAT_DAYS_NO_TZ = "YYYY MMM D,dddd- HH:mm";
export const CHART_HEADER_FORMAT_TZ = "YYYY MMM D, HH:mm z";
export const CHART_HEADER_FORMAT_DAY_ONLY = "YYYY MMM D";

const isEmptyDate = (strDate: string) => {
  if (isNullOrUndefined(strDate) || strDate === "0001-01-01T00:00:00") return true;
  return false;
};

const abbrvMapper = {
  AKDT: { displayName: "(GMT-08:00) Alaska", generalAbbv: "AKT" },
  AKST: { displayName: "(GMT-09:00) Alaska", generalAbbv: "AKT" },
  CDT: { displayName: "(GMT-05:00) Central Time", generalAbbv: "CT" },
  CST: { displayName: "(GMT-06:00) Central Time", generalAbbv: "CT" },
  EDT: { displayName: "(GMT-04:00) Eastern Time", generalAbbv: "ET" },
  EST: { displayName: "(GMT-05:00) Eastern Time", generalAbbv: "ET" },
  HST: { displayName: "(GMT-10:00) Hawaii", generalAbbv: "HST" },
  MDT: { displayName: "(GMT-06:00) Mountain Time", generalAbbv: "MT" },
  MST: { displayName: "(GMT-07:00) Mountain Time", generalAbbv: "MT" },
  PDT: { displayName: "(GMT-07:00) Pacific Time", generalAbbv: "PT" },
  PST: { displayName: "(GMT-08:00) Pacific Time", generalAbbv: "PT" },
};

moment.fn.zoneName = function () {
  const abbr = this.zoneAbbr();
  return abbrvMapper[abbr]?.displayName || abbr;
};

export const getAutoIntervalMinutesByDateRange = (startMoment, endMoment) => {
  const days = endMoment.diff(startMoment, "days");
  const hours = endMoment.diff(startMoment, "hours");

  if (days > 90) {
    return MinutesPerDay;
  } else if (days > 30) {
    return MinutesPerHour * 12;
  } else if (days > 10) {
    return MinutesPerHour * 12;
  } else if (days > 5) {
    return MinutesPerHour * 2;
  } else if (days > 1) {
    return MinutesPerHour;
  } else if (hours >= 10) {
    return 20;
  } else if (hours > 3) {
    return 5;
  }
  return 1;
};

export const getTzAbbrFromDisplayName = (displayName) => {
  const tzKey: any = Object.keys(US_TIME_ZONES_TO_GENERIC).find(
    (key) => US_TIME_ZONES_TO_GENERIC[key].longAbbrv === displayName
  );
  return US_TIME_ZONES_TO_GENERIC[tzKey]?.shortAbbrv;
};

export const getTzDisplayNameFromShortAbbr = (shortAbbrv) => {
  const tzKey: any = Object.keys(US_TIME_ZONES_TO_GENERIC).find(
    (key) => US_TIME_ZONES_TO_GENERIC[key].shortAbbrv === shortAbbrv
  );
  return US_TIME_ZONES_TO_GENERIC[tzKey]?.longAbbrv;
};

export const getMomentTzFromUserTz = (userTimeZone: string) => {
  userTimeZone = userTimeZone || LOCAL_TZ;
  return userTimeZone === LOCAL_TZ
    ? moment.tz.guess()
    : US_TIMEZONES_ABBR_MAPPER[userTimeZone].timeZone;
};

export const formatDateNoTimezone = (strDate, format = TABLE_FORMAT_NO_TZ) => {
  if (isEmptyDate(strDate)) return null;
  return moment.utc(strDate, FULL_DATE_FORMAT).format(format);
};

export const getTimezone = (currentUser) => {
  const userTimeZone = currentUser
    ? getMomentTzFromUserTz(currentUser?.timezone)
    : moment.tz.guess();
  return userTimeZone;
};

export const parseStringServerDate = (strDate, currentUser) => {
  const timezone = getTimezone(currentUser);
  return parseStringServerDateWithTimezone(strDate, timezone);
};

export const parseStringServerDateWithTimezone = (strDate, timezone) => {
  if (isEmptyDate(strDate)) return null;
  const m = moment.utc(strDate, FULL_DATE_FORMAT);
  const mtz = moment.tz(m, timezone);
  return mtz;
};

export const formatDate = (strDate, currentUser, format = TABLE_FORMAT_FULL, inputIsUTC = true) => {
  if (inputIsUTC) {
    // convert utc to local
    const mtz = parseStringServerDate(strDate, currentUser);
    const result = mtz ? mtz.format(format || TABLE_FORMAT_FULL) : null;
    return result;
  } else {
    const m = moment(strDate, FULL_DATE_FORMAT);
    return m.format(format);
  }
};

export const formatDateUnixMS = (epochMS, currentUser, format = TABLE_FORMAT_FULL) => {
  const mtz = parseDateUnixMS(epochMS, currentUser);
  return mtz.format(format);
};

export const parseDateUnixMS = (epochMS, currentUser) => {
  const timezone = getTimezone(currentUser);
  const m = moment.utc(+epochMS);
  const mtz = moment.tz(m, timezone);
  return mtz;
};

export function convertHourToTimezone(utcHour, currentUser) {
  const targetTimezone = getTimezone(currentUser);
  const utcTime = moment().utc().hour(utcHour).minutes(0).seconds(0);

  const estTime = moment.tz(utcTime, targetTimezone).format(`H:mm`);

  return moment(estTime, `H:mm`).hour();
}

export const parseDateUnixMSUTC = (epochMS, currentUser) => {
  const timezone = getTimezone(currentUser);
  const m = moment.utc(+epochMS);

  return m;
};

export const formatDateUnixMSUTC = (epochMS, currentUser, format) => {
  const m = parseDateUnixMSUTC(epochMS, currentUser);
  return m.format(format || TABLE_FORMAT_FULL);
};

/**
 * returns moment object by user's timezone
 * @param {currentUser} currentUser
 */
export const getNow = (currentUser) => {
  const timezone = getTimezone(currentUser);
  const m = moment.utc();
  const mtz = moment.tz(m, timezone);

  return mtz;
};

export const dateDiffInSeconds = (strDate1, strDate2) => {
  if (isEmptyDate(strDate1) || isEmptyDate(strDate2)) return null;
  const m1 = moment(strDate1, FULL_DATE_FORMAT);
  const m2 = moment(strDate2, FULL_DATE_FORMAT);
  return m2.diff(m1, "seconds");
};

export const datesDiff = (strDate1, strDate2) => {
  if (isEmptyDate(strDate1) || isEmptyDate(strDate2)) return null;
  const m1 = moment(strDate1, FULL_DATE_FORMAT);
  const m2 = moment(strDate2, FULL_DATE_FORMAT);
  return m2.diff(m1);
  // const duration = moment.duration(ms);
  // return timeAsText(ms);
};

const cleanArr: any = (arr = []) => {
  return (arr || []).filter((item) => item != null && item != undefined);
};

export const average = (numbersArr: number[]) => {
  const arr = cleanArr(numbersArr);
  if (!Array.isArray(arr) || arr.length === 0) {
    return null;
  }
  return arr.reduce((a, b) => a + b) / arr.length;
};

export const sum = (numbersArr) => {
  const arr = cleanArr(numbersArr);
  if (!Array.isArray(arr) || arr.length === 0) {
    return null;
  }
  return arr.reduce((total, num) => total + num, 0);
};

export const formatTextToPhone = (text) => {
  const x = text.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //debugger;
  return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ""}`;
};

export const formatTextToLinePhone = (text) => {
  const x = text.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,6})/);
  return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ""}${x[4] ? `-${x[4]}` : ""}`;
};

export const setLocalStorageWithExpiry = (key, value, ttlMS) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    expiry: now.getTime() + ttlMS,
    value, // millisec
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const setSessionStorageWithExpiry = (key, value, ttlMS) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    expiry: now.getTime() + ttlMS,
    value, // millisec
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getSessionStorageWithExpiry = (key) => {
  const itemStr = sessionStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getGroupByKey = (fieldVal, nullValueDefault = DEFAULT_GROUP_BY_KEY) => {
  const key = !fieldVal || fieldVal == "null" ? nullValueDefault : fieldVal;
  return key;
};

export const groupBy = (
  arr: any[] = [],
  field: string | ((dataRow: any) => string),
  nullValueDefault = DEFAULT_GROUP_BY_KEY
): { [key: string]: any } => {
  return (arr || []).reduce((result, dataRow) => {
    const groupValue: string = typeof field === "function" ? field(dataRow) : dataRow[field];
    const key = getGroupByKey(groupValue, nullValueDefault);

    (result[key] = result[key] || []).push(dataRow); // group by field ==> object list
    return result;
  }, {});
};

export const countBy = (
  arr: any[] = [],
  field: string | ((dataRow: any) => any),
  nullValueDefault = DEFAULT_GROUP_BY_KEY
): { [key: string]: number } => {
  const grouped = groupBy(arr, field, nullValueDefault);
  const result: { [key: string]: number } = {};
  Object.entries(grouped).forEach(([key, arr]) => {
    result[key] = arr.length;
  });

  return result;
};

export const timespanToHour = (timespan: string) => {
  const splitted = timespan.split(":");
  try {
    return Number.parseInt(splitted[1], 0);
  } catch (error) {
    return null;
  }
};

export const formatTimeSpan = (hour: number, minute: number, second: number): string => {
  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");
  const formattedSecond = second.toString().padStart(2, "0");

  return `${formattedHour}:${formattedMinute}:${formattedSecond}`;
};

export const hourToTimespan = (hourStr: string) => {
  const formattedHour = hourStr && hourStr.length === 1 ? `0${hourStr}` : hourStr;
  return `00:${formattedHour}:00`;
};

export const isObjectEmpty = (obj: any) => {
  return !obj || Object.keys(obj).length === 0;
};

//todo: allow return in a specified unit like 0m instance of 0ms
export const formatTime = (
  ms,
  fractionDigits = null,
  fallbackValue: string | number | undefined | null = undefined
) => {
  if (isNullOrUndefined(ms) || isNaN(ms)) {
    return fallbackValue ?? null;
  }

  if (ms <= 0) return "0 ms";

  let formattedDuration: string;
  if (ms < 1) formattedDuration = `${moment.duration(ms).asMilliseconds().toFixed(2)} ms`;
  else if (ms < 1000) formattedDuration = `${moment.duration(ms).asMilliseconds().toFixed(0)} ms`;
  else if (ms < 60000) {
    formattedDuration = `${formatNumber(
      moment.duration(ms).asSeconds() + "",
      fractionDigits != null ? fractionDigits : 0
    )} s`;
  } else if (ms < 3600000) {
    formattedDuration = `${formatNumber(
      moment.duration(ms).asMinutes() + "",
      fractionDigits != null ? fractionDigits : 1
    )} m`;
  } else if (ms < 86400000) {
    formattedDuration = `${formatNumber(
      moment.duration(ms).asHours() + "",
      fractionDigits != null ? fractionDigits : 1
    )} h`;
  } else {
    formattedDuration = `${formatNumber(
      moment.duration(ms).asDays() + "",
      fractionDigits != null ? fractionDigits : 1
    )} d`;
  }
  return formattedDuration;
};

export const chartNumberFormatter = (value) => {
  if (value < 1) {
    return formatNumber(value, 2);
  }

  return formatNumber(value, 0);
};

export const formatNumber = (
  value: any,
  maximumFractionDigits = 1,
  nanToZero = true,
  fallback: string | undefined = undefined
) => {
  if (typeof maximumFractionDigits === "object") {
    //TODO:for some reason its coming from apexchart with a series object
    maximumFractionDigits = 1;
  }

  if (isNullOrUndefined(value)) return fallback ?? value;
  let numberValue = value;
  if (typeof value === "string") {
    numberValue = stringToNumber(value);
  }
  if (nanToZero && Number.isNaN(numberValue)) {
    //use fallback instead
    return 0;
  } else if (fallback && Number.isNaN(numberValue)) {
    return fallback;
  }
  //todo: is it correct to override parameters
  if (+numberValue != 0) {
    if (Math.abs(+numberValue) < 0.1) {
      //0.02
      maximumFractionDigits = 2;
    } else if (Math.abs(+numberValue) < 1) {
      //0.5
      maximumFractionDigits = 1;
    }
  } else {
    maximumFractionDigits = 0;
  }

  return new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(+numberValue);
};

export const formatCost = (value: any) => {
  if (isNullOrUndefined(value)) return value;
  let numberValue = value;
  if (typeof value === "string") {
    numberValue = stringToNumber(value);
  }
  if (Number.isNaN(numberValue)) {
    return 0;
  }

  const parts = numberValue.toFixed(2).split(".");
  const left = parts[0];
  const right = parts[1] || "00";

  const leftArr = left.split("");
  const reversedNum = leftArr.reverse().join("");

  const chunks: any = [];
  for (let i = 0; i < reversedNum.length; i += 1) {
    if (i % 3 === 0) chunks.push(",");
    chunks.push(reversedNum[i]);
  }

  chunks.shift();
  const leftPart = chunks.reverse().join("");
  return `$${leftPart}.${right}`;
};

export const formatPercentage = (
  value: any,
  maximumFractionDigits = 0,
  fallbackValue: string | number | undefined | null = undefined
) => {
  if (isNullOrUndefined(value) || isNaN(value)) {
    return fallbackValue ?? value;
  }
  return `${formatNumber(value, maximumFractionDigits)}%`;
};

export const getLimitedPercentageText = (percentage, maximumFractionDigits = 0) => {
  if (isNullOrUndefined(percentage)) return "N/A";

  const txt =
    percentage >= -100 && percentage <= 100
      ? formatPercentage(percentage, maximumFractionDigits)
      : percentage < -100
        ? "< 100%"
        : "> 100%";

  return txt;
};
export const stringToNumber = (str) => {
  if (isNullOrUndefined(str)) return str;
  if (typeof str === "number") {
    return str;
  }
  if (str.includes(".")) {
    return Number.parseFloat(str.replace("%", "").replace(",", ""));
  } else {
    return Number.parseInt(str.replace("%", "").replace(",", ""));
  }
};

export const getUniqueList = (array = [], field: string) => {
  const s = new Set(array.map((a) => a[field]));
  return Array.from(s).sort();
};

export const hasDuplicates = (array: any[] = []) => {
  return new Set(array).size !== array.length;
};

export const getUniqueSortedList = (array = [], field: string) => {
  const s = new Set(array.map((a) => a[field]));
  return Array.from(s).sort();
};

export const getUniqueSortedArrayItems = (array = []) => {
  const s = new Set(array);
  return Array.from(s).sort();
};

export const formatXml = (xml: string | undefined, tab = "\t") => {
  if (!xml) return "";
  // tab = optional indent value, default is tab (\t)
  let formatted = "";
  let indent = "";

  xml.split(/>\s*</).forEach(function (node) {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
    formatted += `${indent}<${node}>\r\n`;
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
  });
  return formatted.substring(1, formatted.length - 3);
};

export const elasticQueryfromObject = (object) => {
  if (!object || isObjectEmpty(object)) return "";

  return Object.entries(object)
    .map(([key, arr]: any) => {
      return `${key}:(${arr
        .map((a) => `"${a.replace(/\\/g, "\\\\").replace(/-/g, "\\-")}"`)
        .join(" or ")})`;
    })
    .join(" ");
};

/**
 *
 * @param {tets} start
 * @param {*} end
 */
export const parseRelativeDates = (start, end) => {
  return {
    endDate: dateMath.parse(end, { roundUp: true }),
    startDate: dateMath.parse(start),
  };
};
export const getTimeFromRelativeDate = (relativeDate: any, roundUp: any) => {
  //@ts-ignore
  return (roundUp ? dateMath.parse(relativeDate, { roundUp: true }) : dateMath.parse(relativeDate))
    .toDate()
    .getTime();
};

export const getTimeFormatByScale = (start, end) => {
  //moment(dateMath.parse(start));
  const startParsed = dateMath.parse(start);
  const endParsed: any = dateMath.parse(end, { roundUp: true });

  const duration: any = moment.duration(endParsed.diff(startParsed));
  //"YYYY-MM-DD[T]HH:mm:ss"

  if (duration._milliseconds > 172800000) {
    // >48 hours
    return "DD MMM";
  } else if (duration._milliseconds > 7200000) {
    return "HH:mm";
  } else {
    return "HH:mm:ss";
  }
};

export const getCurrentTimezoneOffset = () => {
  const {
    auth: { currentUser },
  }: any = store.getState();
  const tz = getMomentTzFromUserTz(currentUser?.timezone);
  const mtz = moment.tz(moment.utc(), tz);
  return mtz.utcOffset() / 60;
};

const roundDate = (momentDate, diffInMinutes) => {
  let result;
  if (diffInMinutes < 15) {
    return momentDate;
  } else if (diffInMinutes <= 60) {
    result = momentDate.startOf("minute");
  } else if (diffInMinutes <= MinutesPerDay) {
    const currentMinute = momentDate.minutes();
    const mult = Math.round(currentMinute / 15);
    result = momentDate.startOf("hour").add(mult * 15, "minutes");
  } else {
    // round to the nearset hour
    result = momentDate.add(30, "minutes").startOf("hour");
  }
  return result;
};

export const convertUIDatesToMomentUserTzDates = (start, end, shouldBeExact = false) => {
  const {
    auth: { currentUser },
  }: any = store.getState();
  const tz = getMomentTzFromUserTz(currentUser?.timezone);
  const mtz: any = moment.tz(moment.utc(), tz);
  const toRound = !shouldBeExact && isRelativeDate(start) && isRelativeDate(end);
  const nowOnUserTimezone = moment.utc().add(mtz._offset, "minutes").format(FULL_DATE_FORMAT);
  const nowOnUserTimezoneDateObj = moment(nowOnUserTimezone).toDate(); //force now to be equal to user timezone
  const startParsed = dateMath.parse(start, { forceNow: nowOnUserTimezoneDateObj });
  const endParsed = dateMath.parse(end, { forceNow: nowOnUserTimezoneDateObj, roundUp: true });
  const diffInMinutes = endParsed?.diff(startParsed, "minutes");
  const roundedStart = toRound ? roundDate(startParsed, diffInMinutes) : startParsed;
  const roundedEnd = toRound ? roundDate(endParsed, diffInMinutes).add(1, "second") : endParsed;
  //debugger;
  return {
    end: moment.tz(roundedEnd?.format(FULL_DATE_FORMAT), tz),
    start: moment.tz(roundedStart?.format(FULL_DATE_FORMAT), tz),
  };
};

export const isRelativeDate = (dateStr) => {
  return typeof dateStr === "string" && dateStr.includes("now");
};

export const getDatesParams = (start, end, utc = true, shouldBeExact = false) => {
  const tzDates = convertUIDatesToMomentUserTzDates(start, end, shouldBeExact);
  const endDate =
    tzDates.end > moment.utc()
      ? moment.utc().startOf("minute").format(FULL_DATE_FORMAT)
      : tzDates.end.utc().format(FULL_DATE_FORMAT);

  return {
    endDate: endDate,
    startDate: tzDates.start.utc().format(FULL_DATE_FORMAT),
  };
};

export const getUIDatesAsMoment = (start, end, utc = true, shouldBeExact = false) => {
  const tzDates = convertUIDatesToMomentUserTzDates(start, end, shouldBeExact);

  if (utc) {
    return {
      endDate: tzDates.end.utc(),
      startDate: tzDates.start.utc(),
    };
  } else {
    return {
      endDate: tzDates.end,
      startDate: tzDates.start,
    };
  }
};

export const getDaysAndHoursRanges = (
  start,
  end,
  days,
  timeWindowIds,
  includeKeyInfo = false,
  utc = true
) => {
  const tzDates = convertUIDatesToMomentUserTzDates(start, end);

  const hoursDateRanges: any = [];
  const daysDateRanges: any = [];
  (days || []).forEach((d) => {
    const r = getDayRanges(tzDates.start, tzDates.end, d);
    if (includeKeyInfo) {
      //@ts-ignore
      daysDateRanges.push({ key: DAYS.find((day) => day.id == d).name, ranges: r });
    } else {
      daysDateRanges.push(...r);
    }
  });
  (timeWindowIds || []).forEach((twId) => {
    const hr: any = TIME_FRAMES.find((t) => t.id === twId);

    const r = getRanges(tzDates.start, tzDates.end, hr, utc);
    if (includeKeyInfo) {
      hoursDateRanges.push({ key: hr.name, ranges: r });
    } else {
      hoursDateRanges.push(...r);
    }
  });
  const result: any = {};

  if (hoursDateRanges.length > 0) result.hoursDateRanges = hoursDateRanges;
  if (daysDateRanges.length > 0) result.daysDateRanges = daysDateRanges;
  return result;
};

export const isDayInRange = (start, end, dayString, globalTopFilterObject) => {
  const userTz = convertUIDatesToMomentUserTzDates(start, end, true);
  const day: any = DAYS.find((d) => d.name === dayString || d.short === dayString);

  // check also that it's not in global filter
  if (!isNullOrUndefined(globalTopFilterObject?.DayOfWeek)) {
    if (!globalTopFilterObject?.DayOfWeek?.includes(day.id)) {
      return false;
    }
  }

  const arr = getDayRanges(userTz.start, userTz.end, day.id);
  return arr.length > 0;
};
export const isHourInRange = (start, end, hourString, globalTopFilterObject) => {
  const userTz = convertUIDatesToMomentUserTzDates(start, end, true);
  const hr: any = TIME_FRAMES.find((d) => d.name === hourString || d.short === hourString);

  // check also that it's not in global filter
  if (!isNullOrUndefined(globalTopFilterObject?.TimeOfDayWindowID)) {
    if (!globalTopFilterObject?.TimeOfDayWindowID?.includes(hr.id)) {
      return false;
    }
  }

  const arr = getRanges(userTz.start, userTz.end, hr);
  return arr.length > 0;
};

export const getDatesAndHoursParams = (start, end, days, timeWindowIds, utc = true) => {
  const mainDates = getDatesParams(start, end, utc);
  if (days || timeWindowIds) {
    return {
      ...getDaysAndHoursRanges(start, end, days, timeWindowIds, false, utc),
      ...mainDates,
    };
  } else {
    return mainDates;
  }
};

export const removeEmptyPropsFromObject = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val === null || val === undefined) delete obj[key];
  });
  return obj;
};

export const getFilterObjectByFieldsList = (
  globalTopFilterObject = {},
  filterFields: string[] | FilterField[] = [],
  filterAsArray = true
) => {
  const filterObj = {};

  if (filterFields.length == 0) return filterObj;

  Object.entries(globalTopFilterObject).forEach(([key, val]) => {
    //@ts-ignore
    const filterField: string | FilterField = filterFields
      .filter((item: string | FilterField | null | undefined) => !isNullOrUndefined(item))
      .find((item: string | FilterField) => {
        return typeof item == "string" ? item == key : item.field == key;
      });

    if (filterField && Array.isArray(val) && val.length > 0) {
      const filterName =
        typeof filterField === "string"
          ? filterField
          : filterField?.sendToServerName ?? filterField.field;

      if (filterAsArray && val.filter((i) => i !== null)?.length > 0) {
        filterObj[filterName] = val;
      }
      if (!filterAsArray) {
        filterObj[filterName] = val[0];
      }
    }
  });

  return filterObj;
};

export const getBoolFilter = (filtersObject = {}, aliasMap = {}) => {
  const globalFilters: any = { ...filtersObject };
  if (globalFilters?.tag) delete globalFilters.tag;

  const result = {
    must: {},
    mustNot: {},
  };

  Object.entries(cleanObj(globalFilters)).forEach(([key, arrValues]) => {
    const values = !Array.isArray(arrValues) ? [arrValues] : arrValues;

    const filterKey = aliasMap[key] ?? key;
    if (values.length) {
      values.forEach((val) => {
        if (`${val}`.length > 0) {
          if (`${val}`?.startsWith(NEGATE_FILTER)) {
            let valuesList = result.mustNot[filterKey];
            if (!valuesList) {
              result.mustNot[filterKey] = [];
              valuesList = result.mustNot[filterKey];
            }
            valuesList.push(`${val}`.replace(NEGATE_FILTER, ""));
          } else {
            let valuesList = result.must[filterKey];
            if (!valuesList) {
              result.must[filterKey] = [];
              valuesList = result.must[filterKey];
            }
            valuesList.push(val);
          }
        }
      });
    } else {
      // result.must[key] = ["xxxtttgycm33454nhgh32839fj"]; // random val instead of empty array
    }
  });
  // console.log("getFiltersAndNegateFilters", filtersObject, result)
  return result;
};

export const arrayWithoutElementAtIndex = function (arr, index) {
  return (arr || []).filter((value, arrIndex) => index !== arrIndex);
};
export const addRowNumber = (arr, rowId = TABLE_ROW_ID) => {
  if (!arr || !Array.isArray(arr) || arr.length == 0) return arr;
  let counter = 0;
  arr.forEach((row) => {
    counter += 1; // 1 based
    row[rowId] = counter;
  });

  return arr;
};

export const addTimeFrame = (
  data = [],
  currentUser: any, //todo
  histogramIntervalMinutes: number,
  dateField = "datetimeInstance"
) => {
  // this gives an object with dates as keys

  data.forEach((row: any = {}) => {
    const timezone = getTimezone(currentUser);
    const to = parseStringServerDateWithTimezone(row[dateField], timezone);

    if (histogramIntervalMinutes >= MinutesPerDay) {
      row._timeframe = `${to?.format("dddd")}`; //Sunday Monday ... Friday Saturday
    } else {
      const from = to?.clone()?.subtract(histogramIntervalMinutes, "minutes");
      row._timeframe = `${from?.format("HH:mm")} - ${to?.format("HH:mm")}`;
    }
  });
};

export const sortListByProperty = (arr: any[], prop: string, order: "asc" | "desc" = "desc") => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  return order === "asc"
    ? arr.sort((a, b) => {
        if (a[prop].localeCompare) {
          return a[prop]?.toString()?.localeCompare(b[prop]?.toString());
        } else {
          return a[prop] > b[prop] ? 1 : -1;
        }
      })
    : arr.sort((a, b) => {
        if (b[prop].localeCompare) {
          return b[prop]?.toString()?.localeCompare(a[prop]?.toString());
        } else {
          return b[prop] > a[prop] ? 1 : -1;
        }
      });
};

export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};

export const getRandomString = (length = 10) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getErrorMessagesFromApiResponse = (apiResponse) => {
  return apiResponse?.errors?.flatMap((e) => e.message.split(";"))?.filter((err) => err) ?? [];
};

export const filterArrayByFilterObjectAndSearchStr = (
  data,
  globalTopFilterObject,
  filterFields
) => {
  const toCheck = data || [];
  const filter = getFilterObjectByFieldsList(globalTopFilterObject, filterFields);

  const filtered = toCheck
    .filter((row) => {
      return Object.entries(row).every(([fieldName, fieldValue]) => {
        const arrValues = filter[fieldName]; // fiter for this field

        if (!arrValues || arrValues.length === 0) {
          // no filter defined for this field
          return true;
        }

        const mustFilters = arrValues.filter((v) => !v?.startsWith(NEGATE_FILTER));

        const mustNotFilters = arrValues.filter((v) => v?.startsWith(NEGATE_FILTER));

        return (
          (mustFilters.length === 0 ||
            mustFilters.some(
              // any match with include values
              (singleFilterValue) =>
                singleFilterValue.toLowerCase() === `${fieldValue}`.toLowerCase()
            )) &&
          (mustNotFilters.length === 0 ||
            mustNotFilters.every(
              // all items do not match the excluded values
              (singleFilterValue) =>
                singleFilterValue.replace(NEGATE_FILTER, "").toLowerCase() !==
                `${fieldValue}`.toLowerCase()
            ))
        );
      });
    })
    .filter((row) => {
      if (!globalTopFilterObject.filterText || globalTopFilterObject.filterText.length === 0) {
        return true;
      }

      const lower = globalTopFilterObject.filterText.toLowerCase();

      switch (globalTopFilterObject.filterTextAction) {
        case EnumTextFilterActions.Contains:
          return Object.values(row).some((v) => `${v}`.toLowerCase().includes(lower));

        case EnumTextFilterActions.Missing:
          return Object.values(row).every((v) => !`${v}`.toLowerCase().includes(lower));

        default:
          return Object.values(row).some((v) => `${v}`.toLowerCase().includes(lower));
      }
    });

  return filtered;
};

export const formatGB = (gb, unitOnly = false, numberOnly = false, maximumFractionDigits = 1) => {
  if (isNullOrUndefined(gb)) return gb;
  if (gb < 1) return `${formatNumber(gb)} GB`;

  const sizes = ["GB", "TB", "PB", "EB", "ZB", "YB"];
  //@ts-ignore
  const i = parseInt(Math.floor(Math.log(gb) / Math.log(1024)));

  let num = 0;
  if (i == 0) {
    num = gb;
  } else {
    num = gb / Math.pow(1024, i);
  }

  if (numberOnly === true) {
    return formatNumber(num);
  }
  const unit = sizes[i];

  if (unitOnly === true) {
    return unit;
  }

  return `${formatNumber(num, maximumFractionDigits)} ${unit}`;
};

export const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return "0 Bytes";
  // debugger;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${formatNumber(bytes / Math.pow(k, i), dm)} ${sizes[i]}`;
};

export const formatMB = (mb, unitOnly = false, numberOnly = false, maximumFractionDigits = 1) => {
  if (isNullOrUndefined(mb)) return mb;
  if (mb < 1) return `${formatNumber(mb)} MB`;

  const sizes = ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  //@ts-ignore
  const i = parseInt(Math.floor(Math.log(mb) / Math.log(1024)));

  let num = 0;
  if (i == 0) {
    num = mb;
  } else {
    num = mb / Math.pow(1024, i);
  }

  if (numberOnly === true) {
    return formatNumber(num);
  }
  const unit = sizes[i];

  if (unitOnly === true) {
    return unit;
  }

  const formatterNumber = formatNumber(num, maximumFractionDigits);
  return `${formatterNumber} ${unit}`;
};

export const formatDays = (days) => {
  if (isNullOrUndefined(days)) return `${formatNumber(0)} days`;
  if (days === 0) return `0 days`;

  if (days == 1) {
    return `${days} day`;
  } else if (days < 31) {
    return `${days} days`;
  } else if (days >= 31) {
    const m = Math.round(days / 31);
    return m == 1 ? `${m} month` : `${m} months`;
  } else if (days >= 365) {
    const y = Math.round(days / 365);
    return y == 1 ? `${y} year` : `${y} years`;
  }
};

export const calculatePercentageChange = (previous, current) => {
  if (isNullOrUndefined(previous) || previous === 0) return 0;

  if (isNullOrUndefined(current) || current === 0) return -100;

  const change = ((current - previous) / previous) * 100;
  // console.log("calculatePercentageChange", previous, current, change);
  return change;
};

/** treat zero 'previous' as 0.00001 */
export const calculatePercentageChange2 = (previous, current) => {
  if (isNullOrUndefined(previous)) return null;

  if (isNullOrUndefined(current)) return null;

  const change = ((current - previous) / (previous === 0 ? 0.00001 : Math.abs(previous))) * 100;

  return change;
};

export const addTimestampField = (arr, dateStrField = "date", format = FULL_DATE_FORMAT) => {
  (arr || []).forEach((item) => {
    item._timestamp = moment.utc(item[dateStrField], format).valueOf();
  });
};

export const getNextHourOcurrence = (minDate, maxDate, fromHour, hoursRange) => {
  const currentHour = minDate.hour();
  const deltaFromNow = fromHour - currentHour;
  const startDate = minDate.clone().add(deltaFromNow, "hours").startOf("hour");
  const toDate = startDate.clone().add(hoursRange, "hours");

  return {
    from: startDate < minDate ? minDate : startDate,
    to: toDate > maxDate ? maxDate : toDate,
  };
};

export const mergeArraysByProperty = (a = [], b = [], prop) => {
  const reduced = a.filter((aitem) => !b.find((bitem) => aitem[prop] === bitem[prop]));
  return reduced.concat(b);
};

export const getRanges = (fromDate, untilDate, range, utc = true) => {
  //debugger;
  const ranges: any = [];
  let nextDate = fromDate.clone();

  while (nextDate < untilDate) {
    const res = getNextHourOcurrence(nextDate, untilDate, range.startHour, range.hours);
    nextDate = res.to.clone().add(24 - range.hours, "hours");
    if (res.to < res.from) {
      continue;
    }
    const rangeAsString = getDatesParams(res.from, res.to, utc);
    ranges.push(rangeAsString);
  }
  return ranges;
};

const getDayRanges = (fromDate, untilDate, day, utc = true) => {
  const dayRanges: any = [];
  let momentDay = day - 1;
  momentDay = momentDay === 0 ? 7 : momentDay;
  //const dayINeed = 4; // for Thursday
  let startDate;
  if (fromDate.isoWeekday() <= momentDay) {
    startDate = fromDate.clone().isoWeekday(momentDay).startOf("day");
  } else {
    startDate = fromDate.clone().add(1, "weeks").isoWeekday(momentDay).startOf("day");
  }

  while (startDate < untilDate) {
    const endDate = startDate.clone().endOf("day");
    dayRanges.push({
      ...getDatesParams(
        startDate < fromDate ? fromDate.clone() : startDate.clone(),
        endDate > untilDate ? untilDate.clone() : endDate.clone(),
        utc,
        true
      ),
    });
    startDate = startDate.add(1, "weeks");
  }
  return dayRanges;
};

const DATE_RANGE_FORMATS: { [key: string]: string } = {
  DR: "1{LL} — 2{LL}",
  DRD: "1{MMMM D, YYYY}",
  DRDT: "1{MMMM D, YYYY} 1{LT} ‒ 2{LT}",
  DRE: "[To] LL",
  DRET: "[To] LLL",
  DRM: "1{MMMM D} ‒ 2{D, YYYY}",
  DRMT: "1{MMMM D LT} — 2{MMMM D LT, YYYY}",
  DRS: "[From] LL",
  DRST: "[From] LLL",
  DRT: "1{LLL} — 2{LLL}",
  DRY: "1{MMMM D} — 2{MMMM D, YYYY}",
  DRYT: "1{MMMM D LT} — 2{MMMM D LT, YYYY}",
};

const MIN_TIMESTAMP = -8640000000000000,
  MAX_TIMESTAMP = 8640000000000000;

export const formatDateRange = function (range, opts) {
  if (typeof opts === "string") {
    opts = { format: opts };
  } else if (typeof opts === "undefined") {
    opts = {};
  }
  const options = Object.assign(
    {
      collapse: "date",
      openRange: true,
      showTime: true,
    },
    opts
  );

  const start = range.start,
    end = range.end;

  const hasStart = options.openRange && !start.isSame(MIN_TIMESTAMP),
    hasEnd = options.openRange && !end.isSame(MAX_TIMESTAMP);

  const sameYear = start.year() == end.year(),
    sameMonth = sameYear && start.month() == end.month(),
    sameDate = sameMonth && start.date() == end.date();

  if (!options.format) {
    let f = "DR";
    if (hasStart && !hasEnd) {
      f += "S";
    } else if (!hasStart && hasEnd) {
      f += "E";
    } else
      switch (options.collapse) {
        case "date":
          if (sameDate) {
            f += "D";
          }
          break;
        case "month":
          if (sameMonth) {
            f += "M";
          }
          break;
        case "year":
          if (sameYear) {
            f += "Y";
          }
          break;
      }
    if (options.showTime) {
      f += "T";
    }
    //@ts-ignore
    const formats = moment.localeData()._dateRangeFormat;
    // eslint-disable-next-line no-prototype-builtins
    options.format = formats && formats.hasOwnProperty(f) ? formats[f] : DATE_RANGE_FORMATS[f];
  }
  options.format = options.format
    .replace(/\[.*?\]|1\{(.+?)\}/g, function (m, g) {
      if (!g) return m;
      return "[[]" + g + "[]]";
    })
    .replace(/\[.*?\]|2\{(.+?)\}/g, function (m, g) {
      if (!g) return m;
      return "[" + g + "]";
    });

  let res = "";
  if (hasStart) {
    res = start.format(res || options.format);
  }
  if (hasEnd) {
    res = end.format(res || options.format);
  }
  return res;
};

export const cleanObj = (obj) => {
  if (!obj) return obj;
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};
export const abbreviateNumber = (number: number) => {
  if (isNullOrUndefined(number)) {
    return null;
  }
  if (number < 1e3) return formatNumber(number, 1);
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + " K";
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + " M";
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + " B";
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + " T";
};

export const pick = function (obj: any, props: string[]) {
  // Make sure object and properties are provided
  if (!obj || !props) return obj;

  // Create new object
  const picked: any = {};

  // Loop through props and push to new object
  props.forEach((prop) => {
    picked[prop] = obj[prop];
  });

  // Return new object
  return picked;
};

export const omit = function <T>(obj: T, prop: keyof T): Omit<T, keyof T> {
  if (!obj) {
    return obj;
  }

  const newObj = { ...obj };
  delete newObj[prop];
  return newObj;
};

export function removeKeysFromObject(obj = {}, keysToRemove: any[]) {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (keysToRemove.includes(key)) {
      delete newObj[key];
    }
  });
  return newObj;
}

export const msToTimeSpan = (ms: number) => {
  let seconds: any = ms / 1000;
  //@ts-ignore
  let hours: any = parseInt(seconds / 3600);
  hours = hours.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
  seconds = seconds % 3600;
  //@ts-ignore
  let minutes = parseInt(seconds / 60);
  //@ts-ignore
  minutes = minutes.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });

  seconds = Math.round((seconds % 60) * 100) / 100;
  seconds = seconds.toLocaleString("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${hours}:${minutes}:${seconds}`;
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const filterNoOp = () => true;
export const noOp = (el: any) => el;
export const noOpReact = () => null;

export const noOpPromise = Promise.resolve(true);

export const titleCase = (str) => {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const getHealthAsString = (downSinceDateTime, currentUser) => {
  if (downSinceDateTime) {
    //@ts-ignore
    const ms = getNow(currentUser).diff(parseStringServerDate(downSinceDateTime));
    const duration = moment.duration(ms);
    return `Started: ${formatDate(
      downSinceDateTime,
      currentUser
    )}. Duration: ${duration.humanize()} Down`;
  }
  return "Healthy";
};

export const getSilencingStatus = (row, groupedByServer) => {
  const serverName = row.serverName;
  const silencedForServer = groupedByServer[serverName] || [];
  const nowUTC = moment.utc();

  let activeSilencingWindows = [];
  let silencingStatus: any = SilencingStatus.NoSilencingWindowsScheduled; // initial> No silencing windows scheduled
  if (silencedForServer.length > 0) {
    activeSilencingWindows = silencedForServer.filter((s) => {
      const swStart = parseStringServerDateWithTimezone(s.startTime, s.serverTimeZone);
      const swEnd = parseStringServerDateWithTimezone(s.endTime, s.serverTimeZone);
      //@ts-ignore
      return swStart <= nowUTC && nowUTC < swEnd;
    });

    if (activeSilencingWindows.length > 0) {
      silencingStatus = SilencingStatus.ActiveSilencingWindow;
    } else {
      silencingStatus = SilencingStatus.FutureSilencingWindow;
    }
  }
  return silencingStatus;
};

export const ChangeDiffUnknown = 100_000_000;

export const getChangePercent = function (newNumber: number, originalNumber: number) {
  if (newNumber == originalNumber) return 0;
  const diff = Math.abs(originalNumber - newNumber);
  const decrease = newNumber < originalNumber;

  if (newNumber == 0) {
    return -100;
  }

  if (originalNumber == 0 && newNumber > 0) {
    return ChangeDiffUnknown;
  }

  const factor = decrease ? -1 : 1;
  return (factor * (100 * diff)) / originalNumber;
};

export const getValidHtmlId = (id = "") => {
  return id.replace(/\W/g, "_");
};

export const getFirstNonNullUndefined = (arr: any[] = []) => {
  return arr.find((item) => !isNullOrUndefined(item));
};

export const arrayIfEmpty = (arr: any[], fallbackArr: any[]) => {
  if (arr.length === 0) {
    return fallbackArr;
  }
  return arr;
};

export const pngExportFilter = (node: HTMLElement) => {
  const exclusionClasses = [ExportHiddenClass, "apexcharts-tooltip"];
  return !exclusionClasses.some((className) => node.classList?.contains(className));
};

export const generateFileName = (format) => {
  const date = moment(new Date()).format("YYYYMMDD");
  const urlArr = location.pathname.split("/");
  const path =
    urlArr.length > 2
      ? urlArr
          .filter((_, i) => i !== 0)
          .join(",")
          .replace(",", "_")
      : urlArr[urlArr.length - 1];

  const screenName = path?.includes("_")
    ? path
        ?.split("_")
        ?.map((i) => capitalizeFirstLetter(i).replace("_", "-"))
        ?.join("-")
    : capitalizeFirstLetter(path);

  return `${screenName}-${date}.${format}`;
};

//https://github.com/bubkoo/html-to-image#react
export const handlePNGExport = (ref: RefObject<HTMLDivElement>) => {
  if (ref.current === null) {
    return;
  }

  toPng(ref.current, {
    backgroundColor: "#ffffff",
    filter: pngExportFilter,
    style: { padding: "20px" },
  })
    .then((dataUrl) => {
      const link = document.createElement("a");
      link.download = generateFileName("png");
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const handlePDFExport = (ref: RefObject<HTMLDivElement>) => {
  if (ref.current === null) {
    return;
  }

  const canvas = ref.current; // document.getElementById(`container_${ chartId.current } `);
  if (canvas != null) {
    let width = canvas?.clientWidth;
    let height = canvas?.clientHeight;

    toPng(canvas, {
      backgroundColor: "#ffffff",
      filter: pngExportFilter,
    }).then((imgURI) => {
      const doc =
        width > height
          ? new jsPDF("l", "px", [width, height])
          : new jsPDF("p", "px", [height, width]);

      //then we get the dimensions from the 'pdf' file itself

      width = doc.internal.pageSize.getWidth();
      height = doc.internal.pageSize.getHeight();
      const widthWithPadding = width - 20;
      const heightWithPadding = height - 20;

      doc.addImage(imgURI, "PNG", 10, 40, widthWithPadding, heightWithPadding);
      doc.save(generateFileName("pdf"));
    });
  }
};

export const getLogoMimeType = (logoFileType: any) => {
  let dataType = "";
  switch (logoFileType) {
    case "image/png":
      dataType = "data:image/png;base64,";
      break;
    case "image/jpeg":
      dataType = "data:image/jpeg;base64,";
      break;
    case "image/jpg":
      dataType = "data:image/jpg;base64,";
      break;
    case "image/svg+xml":
      dataType = "data:image/svg+xml;base64,";
      break;
    default:
      console.error("Unknown Logo Type", logoFileType);
      break;
  }

  return dataType;
};
export const diffTo100 = (val) => {
  if (isNullOrUndefined(val)) return val;
  return 100 - val;
};

const getTagFilterType = (filterFields) => {
  if (filterFields.includes("hosts")) return "Server";
  if (filterFields.includes("servername") && !filterFields.includes("databasename"))
    return "Server";
  if (filterFields.includes("servername") && filterFields.includes("databasename"))
    return "ServerAndDatabase";
  if (!filterFields.includes("servername") && filterFields.includes("databasename"))
    return "Database";
};

export const getTagFilter = (filterFields, tags) => {
  if (tags?.length > 0) {
    return {
      tagFilter: {
        filterType: getTagFilterType(filterFields),
        tags: tags?.map((i) => {
          const tag = i.split(":");
          return {
            name: tag[0].trim(),
            value: tag[1].trim(),
          };
        }),
      },
    };
  } else {
    return {};
  }
};

export const getSilencingStatusNumber = (s) => {
  if (s === "None") return 0;
  if (s === "Active") return 1;
  if (s === "Future") return 2;
  return 0;
};

export const getTableStyles = (data) => {
  if (data.length > 0) {
    return "table-portal table-header-borders table-borders fd-data-table-bordered";
  }
  return "table-portal table-header-borders table-borders bottom";
};

export const checkRouteExceptions = () => {
  const exceptions = ["performance_overview", "ask_wisdom", "assessment"];
  return exceptions.some((i) => window.location.pathname.includes(i));
};

export const getV2Filter = (filtersObject: any = {}, aliasMap = {}) => {
  const result = {};
  const globalFilters: any = { ...filtersObject };
  if (globalFilters?.tag) delete globalFilters.tag;

  Object.entries(cleanObj(globalFilters)).forEach(([key, arrValues]) => {
    const values = !Array.isArray(arrValues) ? [arrValues] : arrValues;
    const filterKey = aliasMap[key] ?? key;

    if (values.length) {
      values.forEach((val) => {
        if (`${val}`.length > 0) {
          if (`${val}`?.startsWith(NEGATE_FILTER)) {
            let valuesList = result[filterKey]?.excludeValues;
            if (!valuesList) {
              result[filterKey] = {
                ...result[filterKey],
                excludeValues: [],
              };
              valuesList = result[filterKey].excludeValues;
            }
            valuesList.push(`${val}`.replace(NEGATE_FILTER, ""));
          } else {
            let valuesList = result[filterKey]?.includeValues;
            if (!valuesList) {
              result[filterKey] = {
                ...result[filterKey],
                includeValues: [],
              };
              valuesList = result[filterKey]?.includeValues;
            }
            valuesList.push(val);
          }
        }
      });
    }
  });

  return Object.keys(result).length > 0 ? result : {};
};

export const getV2DatesParams = (start, end, shouldBeExact = false) => {
  const tzDates = convertUIDatesToMomentUserTzDates(start, end, shouldBeExact);
  const endDate =
    tzDates.end > moment.utc()
      ? moment.utc().startOf("minute").format(FULL_DATE_FORMAT)
      : tzDates.end.utc().format(FULL_DATE_FORMAT);

  return {
    startDateTime: tzDates.start.utc().format(FULL_DATE_FORMAT),
    endDateTime: endDate,
  };
};

export const getV2TagFilter = (tags) => {
  return tags?.length > 0
    ? {
        tag: {
          includeTags: tags
            ?.filter((t) => !t.startsWith(NEGATE_FILTER))
            .map((i) => {
              const tag = i.split(":");
              return {
                name: tag[0].trim(),
                value: tag[1].trim(),
              };
            }),
          excludeTags: tags
            ?.filter((t) => t.startsWith(NEGATE_FILTER))
            .map((i) => {
              const excludeTag = i.split(NEGATE_FILTER)[1];
              const tag = excludeTag.split(":");
              return {
                name: tag[0].trim(),
                value: tag[1].trim(),
              };
            }),
        },
      }
    : {};
};
