import { ReduxAuthState } from "types/app";
import { isLoggedIn } from "../../PermissionsHelpers";
import ActionTypes from "./auth.types";

const INITIAL_STATE: ReduxAuthState = {
  currentUser: null,
  permissions: {},
  isLoggedIn: false,
  allowedClients: [],
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: isLoggedIn(action.payload),
      };

    case ActionTypes.LOGOUT:
      return {
        ...INITIAL_STATE,
      };

    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,

        currentUser: action.payload.currentUser,
        isLoggedIn: isLoggedIn(action.payload),
        allowedClients: action.payload.allowedClients,
      };

    case ActionTypes.PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };

    case ActionTypes.ALLOWED_CLIENTS:
      return {
        ...state,
        allowedClients: action.payload.allowedClients,
      };

    default:
      return state;
  }
};

export default authReducer;
