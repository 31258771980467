/**https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin */

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import config from "./config.json";

/**
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 */
const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    // portaldev
    extensions: [reactPlugin],
    instrumentationKey:
      config.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY || "50757394-f16b-43ea-a06b-8f45c2afe6a3",
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
