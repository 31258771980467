const types = {
  SET_CURRENT_FILTER: "SET_CURRENT_FILTER",
  SET_FILTER_MODE: "SET_FILTER_MODE",
  SET_GLOBAL_STATE: "SET_GLOBAL_STATE",
  UPDATE_TOP_FILTER: "UPDATE_TOP_FILTER",
  UPDATE_TOP_FILTER_DATES: "UPDATE_TOP_FILTER_DATES",
  TOGGLE_SIDE_NAV: "TOGGLE_SIDE_NAV",
  CLIENT_INFO: "CLIENT_INFO",
  CLIENT_ID: "CLIENT_ID",
  CLEAR: "CLEAR",
  UPDATE_TOP_ASSESSMENT_FILTER: "UPDATE_TOP_ASSESSMENT_FILTER",
  UPDATE_PERFORMANCE_FILTER: "UPDATE_PERFORMANCE_FILTER",
  CLEAR_PERFORMANCE_FILTER: "CLEAR_PERFORMANCE_FILTER",
  UPDATE_FILTER_BLOCK_HEIGHT: "UPDATE_FILTER_BLOCK_HEIGHT",
  SET_SHOW_V2_PAGES: "SET_SHOW_V2_PAGES",
};

export default types;
