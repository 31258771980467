import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { FORTIFIED_DATA_API_URL } from "./config";
import "./index.css";
import { persistor, store } from "./redux/store";
import { unregister } from "./registerServiceWorker";
import { EuiProvider } from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import createCache from "@emotion/cache";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "./config";

class AnalyticsRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    // IMPORTANT
    // console.log('DebugRouter################################################################################initial history is: ', JSON.stringify(this.history, null, 2))
    this.history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }
}

const cache = createCache({
  key: "wis",
  container: document.querySelector('meta[name="global-style-insert"]'),
});

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AnalyticsRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
          <EuiProvider cache={cache} colorMode="light">
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </EuiProvider>
        </AppInsightsContext.Provider>
      </AnalyticsRouter>
    </PersistGate>
  </Provider>
);

unregister();
