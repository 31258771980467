import { OktaAuth } from "@okta/okta-auth-js";
import { OKTA } from "./config";

const oktaConfig = {
  issuer: OKTA.issuer,
  clientId: OKTA.client_id,
  redirectUri: `${window.location.origin}/implicit/callback`,
};

const OktaAuthService = new OktaAuth(oktaConfig);
export default OktaAuthService;

var lastActiveCallTimeStamp = Math.floor(Date.now() / 1000);

export const getAccessToken = async () => {
  lastActiveCallTimeStamp = Math.floor(Date.now() / 1000);
  const accessToken = await OktaAuthService.tokenManager.get("accessToken");
  return accessToken;
};

const deltaInSeconds = 60; // exclude the approximate loading time after login, otherwise first call will return false
export const ShouldExpireDueToInactivity = (currentToken) => {
  const now = Math.floor(Date.now() / 1000);
  const inActivityTime = now - lastActiveCallTimeStamp + deltaInSeconds;
  const decodedToken: any = OktaAuthService.token.decode(currentToken.value);
  const tokenLifeTime: any = decodedToken.payload.exp - decodedToken.payload.iat;
  const result = inActivityTime > tokenLifeTime;
  console.log(`${inActivityTime} Vs ${tokenLifeTime}`);
  return result;
};
