/* eslint-disable */
//as it sorting colors and chnages the charts color- we need to create an array of colors instead of object
import { faMoon, faSun, faSunrise, faSunset } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment-timezone";

export const COPYRIGHT_MESSAGE = `Copyright © ${new Date().getFullYear()} by Fortified`;
// export const isProduction = process.env.NODE_ENV === "production";
export const toastLifeTimeMs = 6000;
export const ErrorLoadingTableData = "Error loading table data.";
export const MAX_SELECTION_DAYS = 190;
export const DEFAULT_GROUP_BY_KEY = "N/A";
export const DEFAULT_PASS_FIELD = "********";
export const FontFamily = ` "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
//  `font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI",
// Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
// "Segoe UI Symbol" "Inter UI", -apple-system, BlinkMacSystemFont,
// "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
// "Segoe UI Emoji", "Segoe UI Symbol";`;
export const FD_CLIENT = 286;
export const DASHBOARD_DATE_PICKER_DAYS = 30;
export const BILLING_CONTACT_ROLE_ID = 3;
export const TABLE_ROW_ID = "row_num";
export const DefaultPageSizeOptions = [5, 10, 15, 25, 50, 100];
export const ALL_OTHER_SERVERS = "All Other Servers";

export const DefaultLabel = "N/A";
export const DefaultValue = "-";
export const EmptyNumberPlaceholder = "- -";
export const DefaultAutoRefreshIntervalMilliseconds = 60000;
export const HOME_PAGE = "/home";
export const ONBOARDING_PAGE = "/account/onboarding";
export const DefaultDateFieldWith = "190px";
export const SupportEmail = "support@fortifiedinsight.com"; //todo: from env varables
export const SalesEmail = "sales@fortifiedinsight.com";
export const DocsUrl = "https://docs.fortifiedwisdom.com/";

export const TableFullScreenRowsPerPage = 15;

export const ElasticSubstrPrefix = "SUBSTR_____";

export const ExportHiddenClass = "export-hidden";
export const windowsMetrics = [
  "windows_server_cpu",
  "windows_server_storage",
  "windows_server_memory",
];

export const sqlMetrics = [
  "sql_server_contention",
  "sql_server_scheduler",
  "sql_server_storage",
  "sql_server_memory",
];
// export const TIMEZONE_OPTIONS = [
//   // todo: move to consts ?
//   { value: "est", label: "EST" },
//   { value: "cst", label: "CST" },
//   { value: "mst", label: "MST" },
//   { value: "pst", label: "PST" },
//   { value: "utc", label: "UTC" },
// ];

export const DAYS_MAP = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};
export const SilencingWindowsState = [
  { id: 1, name: "Active" },
  { id: 2, name: "Future" },
  { id: 3, name: "Completed" },
];

export const CLIENT_STATUSES = [
  { id: 9, name: "Active" },
  { id: 13, name: "Inactive" },
  { id: 12, name: "Onboarding" },
];

export const CLIENT_STATUSES_MAP = Object.freeze({
  Active: 9,
  Inactive: 13,
  Onboarding: 12,
});

export const SilencingStatus = Object.freeze({
  ActiveSilencingWindow: 1,
  FutureSilencingWindow: 2,
  NoSilencingWindowsScheduled: 0,
});

export const DAYS = [
  { id: "1", name: "Sunday", short: "Su" },
  { id: "2", name: "Monday", short: "Mo" },
  { id: "3", name: "Tuesday", short: "Tu" },
  { id: "4", name: "Wednesday", short: "We" },
  { id: "5", name: "Thursday", short: "Th" },
  { id: "6", name: "Friday", short: "Fr" },
  { id: "7", name: "Saturday", short: "Sa" },
];

export const SHORT_DAYS = {
  1: "Su",
  2: "Mo",
  3: "Tu",
  4: "We",
  5: "Th",
  6: "Fr",
  7: "Sa",
};

export const SHORT_DAYS_REVERSE = {
  Fr: 6,
  Mo: 2,
  Sa: 7,
  Su: 1,
  Th: 5,
  Tu: 3,
  We: 4,
};

export const NEGATE_FILTER = "|||||";
// export const TEXT_SEARCH_ACTION_SEP = "|:|:|:|:|";
// export const SEP = "|:|:|:|:|";

export const EnumTextFilterActions = {
  //StartsWith: "1",
  Contains: "2",
  Missing: "3",
};

export const DefaultEnumTextFilterAction = EnumTextFilterActions.Contains;

// export const DATE_RANGES = [
//     { value: moment().date(), label: "Current Month (MTD)", months: 1 }, // moment().date()= day of the month, so it reduce from now the num of days of current date = current month
//     { value: 29, label: "Prev 30 Days", months: 1 },
//     { value: 59, label: "Prev 60 Days", months: 2 },
//     { value: 89, label: "Prev 90 Days", months: 3 },
//     { value: 179, label: "Prev 180 Days", months: 6 },
//     { value: 359, label: "Prev 365 Days", months: 12 },
// ];

export const SelectEmptyValue = "";

function populateTimes(interval = 60) {
  let hours;
  let minutes;
  let ampm;

  const result: any[] = [];

  for (let i = 0; i < 1440; i += interval) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`; // adding leading zero
    }
    ampm = hours % 24 < 12 ? "AM" : "PM";
    hours %= 12;

    if (hours === 0) {
      hours = 12;
    }

    result.push({ id: hours, name: `${hours}:${minutes} ${ampm}` });
  }

  return result;
}
export const TIMES = populateTimes();

export const PLATFORMS = [
  { id: 1, name: "Microsoft SQL Server" },
  { id: 2, name: "Microsoft Windows Machine" },
  { id: 3, name: "Oracle Database" },
];

export const SHORTEN_PLATFORMS = [
  { display: "SQL Server", id: 1, name: "Microsoft SQL Server" },
  { display: "Windows", id: 2, name: "Microsoft Windows Machine" },
  { display: "Oracle", id: 3, name: "Oracle Database" },
];

export const ALERT_PRIORITIES = ["P0/P1", "P2"];

export const MIN_DASHBOARD_ROW_HEIGHT = 230;

export const DASHBOARD_MAIN_CHART_HEIGHT = 350;

export const LOCAL_TZ = "[Browser]";

export const US_TIME_ZONES_TO_GENERIC = {
  "America/Anchorage": { longAbbrv: "Alaska Time", shortAbbrv: "AKT" },
  "America/Chicago": { longAbbrv: "Central Time", shortAbbrv: "CT" },
  "America/Denver": { longAbbrv: "Mountain Time", shortAbbrv: "MT" },
  "America/Los_Angeles": { longAbbrv: "Pacific Time", shortAbbrv: "PT" },
  "America/New_York": { longAbbrv: "Eastern Time", shortAbbrv: "ET" },
  "Asia/Jerusalem": { longAbbrv: "Israel Time", shortAbbrv: "IST" },
  "Europe/London": { longAbbrv: "GMT", shortAbbrv: "GMT" },
  "Pacific/Honolulu": { longAbbrv: "Hawaii-Aleutian Time", shortAbbrv: "HST" },
};

export const US_TIME_ZONES = Object.keys(US_TIME_ZONES_TO_GENERIC);

export const US_TIMEZONES_ABBR_MAPPER = {
  AKT: { timeZone: "America/Anchorage" },
  CT: { timeZone: "America/Chicago" },
  ET: { timeZone: "America/New_York" },
  GMT: { timeZone: "Europe/London" },
  HST: { timeZone: "Pacific/Honolulu" },
  IST: { timeZone: "Asia/Jerusalem" },
  MT: { timeZone: "America/Denver" },
  PT: { timeZone: "America/Los_Angeles" },
};
export const Aggregations = {
  Average: {
    aggFunction: "AVG",
  },
  Max: {
    aggFunction: "MAX",
  },
  Median: {
    aggFunction: "PERCENTILES",
    percents: [50],
  },
  Min: {
    aggFunction: "MIN",
  },
  "Percentile 95%": {
    aggFunction: "PERCENTILES",
    percents: [95],
  },
  "Percentile 99%": {
    aggFunction: "PERCENTILES",
    percents: [99],
  },
};

export const HealthThresholdWorkloadCompare = 10;

export const COLORS = Object.freeze({
  $brandGreen: "#14a05a",
  $euiColorVis1Blue: "#6092c0",
  $brandGreen2: "#A9D8CB",
  $euiColorVis2DarkPink: "#d36086",
  $euiColorVis0_behindText: "#6DCCB1",
  $euiColorVis3Purple: "#9170bb",
  $euiColorVis1_behindText: "#79AAD9",
  $euiColorVis4Pink: "#ca8eae",
  $euiColorVis2_behindText: "#EE789D",
  $euiColorVis5Yellow: "#d6bf57",
  $euiColorVis3_behindText: "#A987D1",
  $euiColorVis6: "#b9a888",
  $euiColorVis0Green: "#54b399",
  $euiColorVis7Orange: "#da8b45",
  $euiColorPrimary: "#006BB4",
  $euiColorVis8: "#aa6556",
  $euiColorLightestShade: "#F5F7FA",
  $euiColorVis9DarkOrange: "#e7664c",
  $euiColorLightShade: "#D3DAE6",
  $euiColorYellow: "#FFC700",
  $euiColorDarkShade: "#69707d",
  $euiRedColorAnomalies: "#BD271E",
  $euiColorDarkestShade: "#343741",
  $euiColorVis4_behindText: "#E4A6C7",
  $chartOrange: "#f5a700",
  $euiColorVis5_behindText: "#F1D86F",
  $chartGreen: "#54b399",
  $euiColorVis6_behindText: "#D2C0A0",
  $darkGrey: "#343741",
  $euiColorVis7_behindText: "#F5A35C",
  $blue: "#016BB4",
  $euiColorVis8_behindText: "#C47C6C",
  $darkGrey2: "#55566e",
  $euiColorVis9_behindText: "#FF7E62",
  $euiColorDanger: "#bd271e",
  $euiColorSecondary: "#017d73",
  $euiColorMediumShade: "#98A2B3",
  $healthy: "#017d73",
  $euiColorWarning: "#f5a700",
  $activityInstance: "#DCE9F3",
  $lightBlue: "#1B8DCD",
  $chartGreen1: "#4A9D61",
  $lightGrey: "#DEE2EA",
  $linkBlue: "#0071c2",
  $medGrey: "#eceff4",
  $progressBehindTestBlue: "#79aad966",
  $progressBehindTestGreen: "#6dccb166",
  $progressBehindTestRed: "#ee789d66",
  $series1: "#0077CC",
  $appBackground: "#f6f7fb",
  $text: "#343740",
  $series2: "#F04E98",
  $textDisabled: "#dde1e8",
  $subdued: "$6a717d",
  $unhealthy: "#ca0000",
  $title: "#1a1c21",
  $trendShadesGreen: "#E3F4EB",
  $trendShadesRed: "#F7E5E4",
  $trendShadesYellow: "#FEF4E0",
});

export const COLORS_3_STEPS_HEALTH = [
  COLORS.$euiColorVis0Green, //green
  COLORS.$euiColorWarning, // orange
  COLORS.$euiColorDanger, //red
];

export const COLORS_5_STEPS_HEALTH = [
  COLORS.$euiColorVis0Green,
  COLORS.$euiColorVis1Blue,
  COLORS.$euiColorYellow,
  COLORS.$euiColorWarning,
  COLORS.$euiColorDanger,
];

export const COLORS_ARR = Object.values(COLORS);

export const TIME_FRAMES = [
  {
    hours: 4,
    icon: faSunrise,
    iconColor: COLORS.$euiColorVis5Yellow,
    id: "1",
    name: "0:00-4:00",
    short: "0-4",
    startHour: 0,
  },
  {
    hours: 4,
    icon: faSun,
    iconColor: COLORS.$euiColorVis5Yellow,
    id: "2",
    name: "4:00-8:00",
    short: "4-8",
    startHour: 4,
  },
  {
    hours: 4,
    icon: faSun,
    iconColor: COLORS.$euiColorVis7Orange,
    id: "3",
    name: "8:00-12:00",
    short: "8-12",
    startHour: 8,
  },
  {
    hours: 4,
    icon: faSunset,
    iconColor: COLORS.$euiColorVis7Orange,
    id: "4",
    name: "12:00-16:00",
    short: "12-16",
    startHour: 12,
  },
  {
    hours: 4,
    icon: faMoon,
    iconColor: COLORS.$euiColorVis1Blue,
    id: "5",
    name: "16:00-20:00",
    short: "16-20",
    startHour: 16,
  },
  {
    hours: 4,
    icon: faMoon,
    iconColor: COLORS.$euiColorVis1Blue,
    id: "6",
    name: "20:00-24:00",
    short: "20-24",
    startHour: 20,
  },
];
export const HoursToTimeFrameLookup = {
  0: TIME_FRAMES[0],
  1: TIME_FRAMES[0],
  10: TIME_FRAMES[2],
  11: TIME_FRAMES[2],

  12: TIME_FRAMES[3],
  13: TIME_FRAMES[3],
  2: TIME_FRAMES[0],
  14: TIME_FRAMES[3],

  3: TIME_FRAMES[0],
  15: TIME_FRAMES[3],
  4: TIME_FRAMES[1],
  16: TIME_FRAMES[4],

  5: TIME_FRAMES[1],
  17: TIME_FRAMES[4],
  6: TIME_FRAMES[1],
  18: TIME_FRAMES[4],

  7: TIME_FRAMES[1],
  19: TIME_FRAMES[4],
  8: TIME_FRAMES[2],
  20: TIME_FRAMES[5],

  9: TIME_FRAMES[2],
  21: TIME_FRAMES[5],
  22: TIME_FRAMES[5],
  23: TIME_FRAMES[5],
};

export const DefaultDates = {
  end: "now",
  start: "now-7d",
  version: 1,
};

const isRageSmallerThanInterval = (start, end, intervalMinutes) => {
  // console.log("isRageSmallerThanInterval - start end--", start?._i, end?._i, intervalMinutes)
  const diffMinutes = end.diff(start, "minutes");
  // console.log("isRageSmallerThanInterval - diff--", diffMinutes, diffMinutes < intervalMinutes)
  return diffMinutes < intervalMinutes;
};

export const AggregationIntervals = [
  //todo: move to consts
  {
    id: "Auto",
    isAllowed: (start, end) => true,
    label: "Auto", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "1m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 1)) return false;
      const hours = end.diff(start, "hours");
      const moreThan30DaysBack = moment().diff(start, "days") > 30;
      return hours <= 24 && !moreThan30DaysBack;
    },
    label: "1min",
  },
  {
    id: "5m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 5)) return false;
      const hours = end.diff(start, "hours");
      const moreThan30DaysBack = moment().diff(start, "days") > 30;
      return hours <= 24 && !moreThan30DaysBack;
    },
    label: "5min",
  },
  {
    id: "15m",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 15)) return false;
      const minutes = end.diff(start, "minutes");
      const moreThan30DaysBack = moment().diff(start, "days") > 30;
      return minutes <= 10 * 24 * 60 && minutes >= 15 && !moreThan30DaysBack;
    },
    label: "15min",
  },
  {
    id: "1h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");

      // console.log("hours diff --- ", hours, days)
      // console.log("hours <= 30 * 24 --- ", hours <= 30 * 24)
      // console.log("hours >= 3 --- ", hours >= 3)
      return days <= 20 && hours >= 3; //hours <= 30 * 24 && hours >= 3;
    },
    label: "1h",
    valueInMinutes: 60,
  },
  {
    id: "3h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 3 * 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");
      return days <= 45 && hours >= 3;
    },
    label: "3h",
  },
  {
    id: "6h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 6 * 60)) return false;
      const hours = end.diff(start, "hours");
      const days = end.diff(start, "days");
      return days <= 60 && hours >= 6;
    },
    label: "6h",
  },
  {
    id: "12h",
    isAllowed: (start, end) => {
      //IsHistogramIntervalValidForTimeRange on server side
      if (isRageSmallerThanInterval(start, end, 12 * 60)) return false;
      const hours = end.diff(start, "hours");
      return hours < 90 * 24 && hours >= 12;
    },
    label: "12h",
    totalHours: 12,
  },
  {
    id: "1d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 1;
      return moreThanInterval;
    },
    label: "1d", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "7d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 7 * 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 7;
      return moreThanInterval;
    },
    label: "7d", //IsHistogramIntervalValidForTimeRange on server side
  },
  {
    id: "30d",
    isAllowed: (start, end) => {
      if (isRageSmallerThanInterval(start, end, 30 * 24 * 60)) return false;
      const moreThanInterval = moment().diff(start, "days") >= 30;
      const days = end.diff(start, "days");
      return days >= 61 && moreThanInterval;
    },
    label: "30d", //IsHistogramIntervalValidForTimeRange on server side
  },
];

export const USTimezoneColors = Object.freeze({
  CST: "#FFFF99",
  EST: "#FFCC99",
  MST: "#CCFFCC",
  PST: "#B2E9F7",
});

export const AllStates = [
  { color: USTimezoneColors.CST, id: "AL", val: "01" },
  { color: USTimezoneColors.EST, id: "AS", val: "60" },
  { color: USTimezoneColors.MST, id: "AZ", val: "04" },
  { color: USTimezoneColors.CST, id: "AR", val: "05" },
  { color: USTimezoneColors.PST, id: "CA", val: "06" },
  { color: USTimezoneColors.MST, id: "CO", val: "08" },
  { color: USTimezoneColors.EST, id: "CT", val: "09" },
  { color: USTimezoneColors.EST, id: "DE", val: "10" },
  { color: USTimezoneColors.EST, id: "DC", val: "11" },
  { color: USTimezoneColors.EST, id: "FL", val: "12" },
  { color: USTimezoneColors.EST, id: "FM", val: "64" },
  { color: USTimezoneColors.EST, id: "GA", val: "13" },
  { color: USTimezoneColors.EST, id: "GU", val: "66" },
  { color: USTimezoneColors.MST, id: "ID", val: "16" },
  { color: USTimezoneColors.CST, id: "IL", val: "17" },
  { color: USTimezoneColors.EST, id: "IN", val: "18" },
  { color: USTimezoneColors.CST, id: "IA", val: "19" },
  { color: USTimezoneColors.CST, id: "KS", val: "20" },
  { color: USTimezoneColors.EST, id: "KY", val: "21" },
  { color: USTimezoneColors.CST, id: "LA", val: "22" },
  { color: USTimezoneColors.EST, id: "ME", val: "23" },
  { color: USTimezoneColors.EST, id: "MH", val: "68" },
  { color: USTimezoneColors.EST, id: "MD", val: "24" },
  { color: USTimezoneColors.EST, id: "MA", val: "25" },
  { color: USTimezoneColors.EST, id: "MI", val: "26" },
  { color: USTimezoneColors.CST, id: "MN", val: "27" },
  { color: USTimezoneColors.CST, id: "MS", val: "28" },
  { color: USTimezoneColors.CST, id: "MO", val: "29" },
  { color: USTimezoneColors.MST, id: "MT", val: "30" },
  { color: USTimezoneColors.CST, id: "NE", val: "31" },
  { color: USTimezoneColors.PST, id: "NV", val: "32" },
  { color: USTimezoneColors.EST, id: "NH", val: "33" },
  { color: USTimezoneColors.EST, id: "NJ", val: "34" },
  { color: USTimezoneColors.MST, id: "NM", val: "35" },
  { color: USTimezoneColors.EST, id: "NY", val: "36" },
  { color: USTimezoneColors.EST, id: "NC", val: "37" },
  { color: USTimezoneColors.CST, id: "ND", val: "38" },
  { color: USTimezoneColors.EST, id: "MP", val: "69" },
  { color: USTimezoneColors.EST, id: "OH", val: "39" },
  { color: USTimezoneColors.CST, id: "OK", val: "40" },
  { color: USTimezoneColors.PST, id: "OR", val: "41" },
  { color: USTimezoneColors.EST, id: "PW", val: "70" },
  { color: USTimezoneColors.EST, id: "PA", val: "42" },
  { color: USTimezoneColors.EST, id: "PR", val: "72" },
  { color: USTimezoneColors.EST, id: "RI", val: "44" },
  { color: USTimezoneColors.EST, id: "SC", val: "45" },
  { color: USTimezoneColors.CST, id: "SD", val: "46" },
  { color: USTimezoneColors.CST, id: "TN", val: "47" },
  { color: USTimezoneColors.CST, id: "TX", val: "48" },
  { color: USTimezoneColors.EST, id: "UM", val: "74" },
  { color: USTimezoneColors.MST, id: "UT", val: "49" },
  { color: USTimezoneColors.EST, id: "VT", val: "50" },
  { color: USTimezoneColors.EST, id: "VA", val: "51" },
  { color: USTimezoneColors.EST, id: "VI", val: "78" },
  { color: USTimezoneColors.PST, id: "WA", val: "53" },
  { color: USTimezoneColors.EST, id: "WV", val: "54" },
  { color: USTimezoneColors.CST, id: "WI", val: "55" },
  { color: USTimezoneColors.MST, id: "WY", val: "56" },
];

export const PatchStatus = Object.freeze({
  Bad: 3,
  OK: 1,
  Warning: 2,
});

export const tzAbbrvMap = {
  "Central America Standard Time": "CST",
  "Central Standard Time": "CST",
  "Coordinated Universal Time": "UTC",
  "Eastern Standard Time": "EST",
  "GMT Standard Time": "GMT",
  "Mountain Standard Time": "MST",
  "Pacific Standard Time": "PST",
  "US Eastern Standard Time": "EST",
  "US Mountain Standard Time": "MST",
};

export enum Subjects {
  Assessment = "Assessment",
  Alerts_Dashboard = "Alerts Dashboard",
  Application_Dashboards = "Application Dashboards",
  Availability_Groups_Dashboard = "Availability Groups Dashboard",
  Billing = "Billing",
  Blocking_Dashboard = "Blocking Dashboard",
  Client_Connections_Dashboard = "Client Connections Dashboard",
  Client_Information = "Client Information",
  Client_MSP_Information = "Client MSP Information",
  Client_Onboarding = "Client Onboarding",
  Collector_Health_Admin = "Collector Health - Admin",
  Collector_Health_Client = "Collector Health - Client",
  Database_Dashboards = "Database Dashboards",
  Deadlocks_Dashboard = "Deadlocks Dashboard",
  Environment_Management = "Environment Management",
  FindingsViewAll = "Findings - View All", //do not change
  Fortified_Support = "Fortified Support",
  Host_Dashboards = "Host Dashboards",
  Instance_Dashboards = "Instance Dashboards",
  Job_Dashboards = "Job Dashboards",
  Maintenance_Task_Management = "Maintenance Task Management",
  Metrics_Dashboard = "Metrics Dashboard",
  Monitoring_Management = "Monitoring Management",
  Query_Statistics_Dashboard = "Query Statistics Dashboard",
  SQL_Activity_Dashboard = "SQL Activity Dashboard",
  SQL_Audit_Dashboard = "SQL Audit Dashboard",
  Security_Dashboard = "Security Dashboard",
  User_Management = "User Management",
  Index_Dashboards = "Index Dashboards",
  Cloud_Migration_Assessment = "Cloud Migration Assessment",
  AskWisdom = "Ask WISdom",
  Tagging = "Tagging",
  V2_Screens = "V2 Screens",
}

//todo: union
export enum Roles {
  Admin = "Admin",
  Read = "Read",
  Write = "Write",
}

export const MAX_PAGE_WIDTH = 1920;

export const PIE_OPTIONS = {
  cpu: "cpu",
  io: "io",
  cost: "cost",
};

// export const EmptyClientID = -99;

// export const EmptyAPIResponse = { data: [] };

export const STATUS = {
  open: "Open",
  closed: "Closed",
  ignored: "Ignored",
  progress: "In Progress",
  pending: "Pending Validation",
};

export const IMPACT_TYPE_COLORS = {
  Security: "#c4dfa5",
  Availability: "#addfdb",
  Performance: "#a1cede",
  "Operational Efficiency": "#f6d487",
  Stability: "#f7bd87",
  Capacity: "#D2C0A0",
  Management: "#6DCCB1",
};

export const APP_STATUS = {
  down: "down",
  offline: "offline",
  healthy: "healthy",
};

export const TABLE_CHIPS_COLORS = {
  0: "#c4dfa5",
  1: "#addfdb",
  2: "#f6d487",
  3: "#f7bd87",
  4: "#a1cede",
  5: "#D2C0A0",
  6: "#6DCCB1",
};
